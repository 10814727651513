<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main3'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import DatePicker from 'vue2-datepicker'
import global_func from '@/global_function'
import moment from 'moment'
import Swal from 'sweetalert2'
import PlanDetail from '@/components/modal/plans_detail_carrier'
import DisplayLocation from '@/components/location/display_start_end_location'
import ModalCompany from '@/components/modal/company'
import ProvinceComp from '@/components/select_components/select_prov'
import TruckTypeComp from '@/components/select_components/select_truckType'
import RighrMap from '@/components/location/right_map'
import VuetifyMoney from '@/components/VuetifyMoney.vue'

//import jbinary from 'jbinary'
/**
 * Starter page component
 */
export default {
  page: {
    title: i18n.t('search_carrier.title'),
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, DatePicker, PlanDetail, DisplayLocation, ModalCompany, ProvinceComp, TruckTypeComp, RighrMap, VuetifyMoney },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('search_carrier')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      check_lang: true,
      lang: lang_obj,

      // setting prefix input
      value5: '',
      label5: 'Money pt-BR',
      readonly5: false,
      disabled5: false,
      outlined5: false,
      clearable5: false,
      options5: {
        locale: 'en-US',
        prefix: '',
        suffix: '',
        length: 9,
        precision: 2,
      },

      //DISPLAY VARIABLE
      plans_display_status: false,

      estimate_this_order: [],

      // goods data
      //dataGoodsType_master: [],
      dataGoodsType_data: [],
      // truck data
      dataTruckType: [],
      dataTruckTypeGroup: [],
      // plans data
      plans_data: [],
      plans_data_status: true,
      plans_row_data: [], // ข้อมูลที่แสดงในแถวแผนงาน
      plan_data_selcet: [],
      plan_location: [],
      // plan display
      activeArrow: [],
      active_tab: 0,

      data_service_etc: [
        {
          service_etc_id: 1,
          name_th: 'พนักงานช่วยขนของ',
          name_en: 'พนักงานช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 2,
          name_th: 'พนักงานเพิ่มเติม',
          name_en: 'พนักงานเพิ่มเติม2',
          service_sel_status: '',
        },
        {
          service_etc_id: 3,
          name_th: 'บริการพาเลทรองพื้น',
          name_en: 'บริการพาเลทรองพื้น2',
          service_sel_status: '',
        },
        {
          service_etc_id: 4,
          name_th: 'อุปกรณ์ช่วยขนของ',
          name_en: 'อุปกรณ์ช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 5,
          name_th: 'อื่นๆ',
          name_en: 'อื่นๆ2',
          service_sel_status: '',
        },
      ],

      //OFFER MODAL
      offer_truck_type: null, //{ id: "3"}, name:"รถบรรทุก 4 ล้อ"
      offer_upload_file: null, //REAL FILE
      offer_upload_display: null, //DISPLAY NAME
      offer_correct_file: true,
      modal_offer: {
        id_order: null,
        good_member_id: null,
        transpot_member_id: null,
        truck_quantity: '',
        cost: '',
        note: '',
      },
      option_truck_type: [],
      //END OFFER MODAL

      //MODAL DETAIL
      offer_detail_data: {},
      //END MODAL DETAIL

      //SEARCH
      // date_search: [new Date(), new Date()],
      date_search: [],
      start_date: '',
      end_date: '',
      province_departure: '',
      province_arrival: '',
      truck_type: '',
      selectedDeparture: '',
      selectedArrival: '',
      selectedTruckType: '',
      obj_sel_depart: {},
      obj_sel_arrive: {},
      selectedIndexCarrier: '',

      plans_data_carrier: [],
      active_carrier: false,
      title_popose: 'เลือกแผนงานที่ต้องการเสนอ (1/2)',
      obj_sel_carrier: {},

      estimate_status: true,
      estimate_display: false,

      subscribe_data: [],
    }
  },
  async mounted() {
    await this.getTruckType()
    await this.getTruckTypeGroup()
    this.makeTruckTypeSelect()
    await this.getDataGoodsType()
    await this.getPlansData()
    await this.getSubscribeData()
  },
  methods: {
    // --- get data part ---
    getTruckType() {
      // var that = this
      service.postService('getTruckType').then(rp => {
        // console.log(rp)
        var data = JSON.parse(JSON.stringify(rp.data))
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
          let row = data[index]
          let rowdata = {
            id_group: row.id_group,
            id_model: row.id_model,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            name_model_en: row.name_model_en,
            name_model_th: row.name_model_th,
            other_flag: row.other_flag,
            prices_pre_km: row.prices_pre_km,
            prices_start: row.prices_start,
            status: '',
          }
          this.dataTruckType.push(JSON.parse(JSON.stringify(rowdata)))
          // that.dataTruckType_ck[index] = ''
        }
        // console.log(that.dataTruckType)
      })
    },
    getTruckTypeGroup() {
      // var that = this
      service.postService('getTruckTypeGroup').then(rp => {
        // console.log(rp)
        var data = rp.data
        for (let index = 0; index < data.length; index++) {
          var row = data[index]
          let rowdata = {
            id_group: row.id_group,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            status: '',
          }
          this.dataTruckTypeGroup.push(JSON.parse(JSON.stringify(rowdata)))
          // that.dataTruckTypeGroup_ck[index] = ''
        }
        // console.log(that.dataTruckTypeGroup)
      })
    },
    findTruckTypeName(truck_type_id) {
      var truck_type_name = ''
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if (truck_type_id == element.id_model) {
          if (truck_type_name != '') {
            truck_type_name += ', '
          }
          if (this.account_lang == 'en') {
            truck_type_name += element.name_model_en
          } else {
            truck_type_name += element.name_model_th
          }
        }
      }
      return truck_type_name
    },
    makeTruckTypeSelect() {
      var data_option = []
      for (var i = 0; i < this.dataTruckTypeGroup.length; i++) {
        var curr_group = this.dataTruckTypeGroup[i]
        data_option.push({
          id_model: 'model' + curr_group.id_group,
          id_group: curr_group.id_group,
          name: curr_group.name_group_th,
          $isDisabled: true,
        })

        this.dataTruckType.map(function(obj) {
          if (curr_group.id_group == obj.id_group) {
            data_option.push({
              id_model: obj.id_model,
              id_group: obj.id_group,
              display_img: false,
              name: obj.name_model_th,
            })
          }
        })

        // console.log(123)
      }
      this.option_truck_type = data_option
    },
    getPlansData() {
      // get ข้อมูล แผนงานขนส่ง
      var that = this
      service
        .postService('searchGoods', {
          mode: 'getData',
          start: this.start_date,
          end: this.end_date,
          user_data: this.account_data,
          prov_depart: this.province_departure,
          prov_arrive: this.province_arrival,
          truck_type: this.truck_type,
        })
        .then(rp => {
          console.log(rp)
          this.plans_data = rp.data
          setTimeout(() => {
            that.createRowOfPlans()
          }, 300)
          // console.log(this.plans_data)
        })
    },
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          //this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          this.dataGoodsType_data.push(goods_type_sel)
        }
      })
    },
    // --- แปลงข้อมูลจาก raw data ---
    getGoodsTypeName(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if (count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getGoodsTypeNameFull(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า แบบเต็ม
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          for (let index = 0; index < this.dataGoodsType_data.length; index++) {
            const element = this.dataGoodsType_data[index]
            if (element.goods_type_id == goodsType_id) {
              if (data_return != '') {
                data_return += ', '
                data_return += element.goods_type_name_th
                if (!this.check_lang) {
                  data_return += element.goods_type_name_en
                }
              } else {
                data_return = element.goods_type_name_th
                if (!this.check_lang) {
                  data_return += element.goods_type_name_en
                }
              }
            }
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getPlanStatus(order_status) {
      var data_return = ''
      if (order_status == '0' || order_status == '1') {
        data_return = 'รอเสนองาน'
      } else if (order_status == '2') {
        data_return = 'รอดำเนินการ'
      } else if (order_status == '3') {
        data_return = 'อยู่ระหว่างขนส่ง'
      } else if (order_status == '4') {
        data_return = 'ดำเนินการเสร็จแล้ว'
      } else if (order_status == '5') {
        data_return = 'ปิดงาน'
      }
      return data_return
    },
    getDateTime(datetime) {
      // แปลงเวลาเป็น format สำหรับแสดงบนเว็บ
      var ck1 = datetime !== null && datetime != null && datetime != undefined
      var data_return = this.lang.no_data_time
      if (ck1) {
        data_return = moment(datetime).format('dd DD/MM/YYYY HH:mm')
      }
      return data_return
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = dateData.diffTime / 1000 / 60 / 60
      var date_return = ''
      if (diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
    // --- plan display part ---
    async createRowOfPlans() {
      var ck1 = this.dataGoodsType_data.length > 0
      var ck2 = this.dataTruckType.length > 0
      var ck3 = this.plans_data.length > 0
      this.plans_row_data = []
      if (ck1 && ck2 && ck3) {
        var rowPlanData = []
        var all_provider_id = []
        for (let index = 0; index < this.plans_data.length; index++) {
          const row = this.plans_data[index]
          // console.log(row)
          let pickup_time = this.getPickupTime(row.pickup_time_1, row.pickup_time_2)
          let vehicle_type_obj = row.vehicle_type_id.split('|')
          var truck_type_name = this.dataTruckType[vehicle_type_obj[0] - 1].name_model_th
          var truck_type_group = this.dataTruckType[vehicle_type_obj[0] - 1].id_group
          if (vehicle_type_obj.length > 1) {
            truck_type_name = truck_type_name + ', ...'
          }
          let data = {}
          data = {
            truck_type_name: truck_type_name,
            truck_type_group: truck_type_group,
            goods_type_name: this.getGoodsTypeName(row.goods_type_id),
            goods_type_name_full: this.getGoodsTypeNameFull(row.goods_type_id),
            status_name: this.getPlanStatus(row.order_status),
            star_score: row.user_score.star_score,
            lover_score: row.user_score.lover_score,
            star_flag: this.findStarFlag(row.id_member),
            count_offer: parseInt(row.count_offer), 
            pickup_time: pickup_time,
            conv_price: global_func.formatPrice(row.expected_price),
            rawData: row,
          }
          // console.log(rowPlanData)
          rowPlanData.push(data)
          all_provider_id.push(row.id_member)
          this.plans_data_status = true
        }
        this.plans_row_data = await this.bindingProviderStar(rowPlanData)
        // console.log(this.plans_row_data)
        let that = this
        setTimeout(function() {
          that.getAllProfilePicture(all_provider_id)
        }, 500)
      } else {
        this.plans_data_status = false
      }
    },
    backToPlanListDisplay() {
      this.plans_display_status = false
    },
    async activePlansDisplay(planData) {
      this.plan_data_selcet = planData
      this.plan_location = []

      this.plan_location[0] = {
        name: planData.rawData.departure_address,
        date_appointment: planData.rawData.pickup_time_1,
      }
      this.plan_location[1] = {
        name: planData.rawData.arrival_address,
        date_appointment: planData.rawData.pickup_time_2,
      }
      var self = this
      setTimeout(function() {
        self.$refs.location_display.show(self.plan_location)
      }, 500)

      //depart_date

      this.plans_display_status = true

      this.getEstimateThisOrder(planData)
    },
    getEstimateThisOrder(order) {
      service
        .postService('getEstimateThisOrder', {
          user_data: this.account_data,
          id_order: order.rawData.id_order,
        })
        .then(rp => {
          // console.log(rp)
          var ck1 = this.dataGoodsType_data.length > 0
          var ck2 = this.dataTruckType.length > 0
          var ck3 = rp.data.length > 0
          var ck4 = rp.dataCarrier.length > 0
          var ck5 = rp.dataCarrier.length == 0
          this.estimate_this_order = []
          this.plans_data_carrier = []
          this.estimate_display = false
          if (ck1 && ck2) {
            if (ck4) {
              var rowPlanCarrier = {}
              for (let index = 0; index < rp.dataCarrier.length; index++) {
                const row = rp.dataCarrier[index]
                let vehicle_type_obj = row.vehicle_type_id.split('|')
                var truck_type_name = this.dataTruckType[vehicle_type_obj[0] - 1].name_model_th
                var truck_type_group = this.dataTruckType[vehicle_type_obj[0] - 1].id_group
                if (vehicle_type_obj.length > 1) {
                  truck_type_name = truck_type_name + ', ...'
                }
                row.truck_type_name = truck_type_name
                rowPlanCarrier = {
                  truck_type_group: truck_type_group,
                  truck_type_name: truck_type_name,
                  status_name: 'ยังไม่เสนอ',
                  rawData: row,
                }
                this.plans_data_carrier.push(rowPlanCarrier)
              }
              this.estimate_status = true
            } else {
              if(ck5) {
                this.estimate_status = true
              } else {
                this.estimate_status = false
              }
            }
            if (ck3) {
              var rowPlanCarrier2 = {}
              let estimate_raw_data = rp.data
              let id_carrier_master = []

              for (let index = 0; index < estimate_raw_data.length; index++) {
                const row2 = estimate_raw_data[index]

                let duplicateFlg = 0
                if(id_carrier_master.length > 0) { // duplicate check 
                  for (let index = 0; index < id_carrier_master.length; index++) {
                    const element2 = id_carrier_master[index]
                    if(row2.id_carrier == element2) {
                      duplicateFlg = 1
                    } else {
                      if(index == (id_carrier_master.length - 1)) {
                        id_carrier_master.push(row2.id_carrier)
                      }
                    }
                  }
                } else {
                  id_carrier_master.push(row2.id_carrier)
                }

                if(duplicateFlg == 0) {
                  let vehicle_type_obj2 = row2.vehicle_type_id.split('|')
                  var truck_type_name2 = this.dataTruckType[vehicle_type_obj2[0] - 1].name_model_th
                  var truck_type_group2 = this.dataTruckType[vehicle_type_obj2[0] - 1].id_group
                  if (vehicle_type_obj2.length > 1) {
                    truck_type_name2 = truck_type_name2 + ', ...'
                  }
                  row2.truck_type_name = truck_type_name2
                  rowPlanCarrier2 = {
                    id_estimate: row2.id_estimate,
                    truck_type_group: truck_type_group2,
                    truck_type_name: truck_type_name2,
                    status_name: 'เสนองานแล้ว',
                    rawData: row2,
                  }
                  this.estimate_this_order.push(rowPlanCarrier2)
                }
              }
              this.estimate_status = false
              this.estimate_display = true
            }
          } else {
            this.estimate_status = false
          }
        })
    },
    bt_company_detail(data) {
      var param_send = { provider_id: data.id_member }
      this.$refs.refCompany.setProviderData(param_send)
      this.$bvModal.show('modal_company_detail')
    },
    //SEARCH
    click_select_depart() {
      var that = this
      that.$bvModal.show('modal_prov_depart')
    },
    click_select_arrive() {
      var that = this
      that.$bvModal.show('modal_prov_arrive')
    },
    select_prov_depart: function(data) {
      // console.log(data)
      this.obj_sel_depart = data
      this.binding_data_prov_depart()
    },
    select_prov_arrive: function(data) {
      // console.log(data)
      this.obj_sel_arrive = data
      this.binding_data_prov_arrive()
    },
    binding_data_prov_depart() {
      this.province_departure = ''
      this.selectedDeparture = ''
      if (Object.keys(this.obj_sel_depart).length) {
        var p1 = this.loop_geo_checked(this.obj_sel_depart[1])
        this.province_departure += p1
        var p2 = this.loop_geo_checked(this.obj_sel_depart[2])
        this.province_departure += p2
        var p3 = this.loop_geo_checked(this.obj_sel_depart[3])
        this.province_departure += p3
        var p4 = this.loop_geo_checked(this.obj_sel_depart[4])
        this.province_departure += p4
        var p5 = this.loop_geo_checked(this.obj_sel_depart[5])
        this.province_departure += p5
        var p6 = this.loop_geo_checked(this.obj_sel_depart[6])
        this.province_departure += p6
      } else {
        this.province_departure = ''
      }
      if (this.province_departure.length > 0) {
        this.province_departure = this.province_departure.slice(0, -1)
        var arr_prov = this.province_departure.split(',')
        var len_prov_dep = arr_prov.length
        if (len_prov_dep > 2) {
          this.selectedDeparture = arr_prov[0] + ' ,' + arr_prov[1] + ', ' + '+' + parseInt(len_prov_dep - 2)
        } else {
          for (var i = 0; i < len_prov_dep; i++) {
            if (i > 0) {
              this.selectedDeparture += ', '
            }
            this.selectedDeparture += arr_prov[i]
          }
        }
      }
    },
    binding_data_prov_arrive() {
      this.province_arrival = ''
      this.selectedArrival = ''
      if (Object.keys(this.obj_sel_arrive).length) {
        var p1 = this.loop_geo_checked(this.obj_sel_arrive[1])
        this.province_arrival += p1
        var p2 = this.loop_geo_checked(this.obj_sel_arrive[2])
        this.province_arrival += p2
        var p3 = this.loop_geo_checked(this.obj_sel_arrive[3])
        this.province_arrival += p3
        var p4 = this.loop_geo_checked(this.obj_sel_arrive[4])
        this.province_arrival += p4
        var p5 = this.loop_geo_checked(this.obj_sel_arrive[5])
        this.province_arrival += p5
        var p6 = this.loop_geo_checked(this.obj_sel_arrive[6])
        this.province_arrival += p6
      } else {
        this.province_arrival = ''
      }
      if (this.province_arrival.length > 0) {
        this.province_arrival = this.province_arrival.slice(0, -1)
        var arr_prov = this.province_arrival.split(',')
        var len_prov = arr_prov.length
        if (len_prov > 2) {
          this.selectedArrival = arr_prov[0] + ' ,' + arr_prov[1] + ', ' + '+' + parseInt(len_prov - 2)
        } else {
          for (var i = 0; i < len_prov; i++) {
            if (i > 0) {
              this.selectedArrival += ', '
            }
            this.selectedArrival += arr_prov[i]
          }
        }
      }
    },
    loop_geo_checked(data) {
      var text = ''
      for (var i = 0; i < data.length; i++) {
        var d = data[i]
        if (d.id != '101' && d.id != '102' && d.id != '103' && d.id != '104' && d.id != '105' && d.id != '106') {
          if (d.status == 'checked') {
            text += d.name_th + ','
          }
        }
      }
      return text
    },
    activeSelectTruckType() {
      // console.log(this.$refs)
      // this.$refs.refSelectTruckType.inputTruckTypeData(this.dataTruckType, this.dataTruckTypeGroup)
      this.$bvModal.show('modal_select_truckType')
    },
    getTruckType_select: function(trucktype, trucktypegroup) {
      this.dataTruckType = trucktype
      this.dataTruckTypeGroup = trucktypegroup
      // console.log(this.dataTruckType)
      // console.log(this.dataTruckTypeGroup)
      this.truck_type = ''
      this.selectedTruckType = ''

      var len_tt = this.dataTruckType.length
      var text_tt = ''
      var id_tt = ''
      for (var i = 0; i < len_tt; i++) {
        var tt = this.dataTruckType[i]
        if (tt.status == 'checked') {
          text_tt += tt.name_model_th + ','
          id_tt += tt.id_model + ','
        }
      }
      if (text_tt != '') {
        this.truck_type = id_tt.slice(0, -1)
        text_tt = text_tt.slice(0, -1)
        var arr_tt = text_tt.split(',')
        var len = arr_tt.length
        if (len > 2) {
          this.selectedTruckType = arr_tt[0] + ' ,' + arr_tt[1] + ', ' + '+' + parseInt(len - 2)
        } else {
          for (var j = 0; j < len; j++) {
            if (j > 0) {
              this.selectedTruckType += ', '
            }
            this.selectedTruckType += arr_tt[j]
          }
        }
      }
    },
    search_goods() {
      if(this.date_search.length > 0) {
        if(this.date_search[0] != '' && this.date_search[1] != '') {
          this.start_date = moment(this.date_search[0]).format('YYYY-MM-DD')
          this.end_date = moment(this.date_search[1]).format('YYYY-MM-DD')
        } else {
          this.date_search[0] = ''
          this.date_search[1] = ''
        }
      } else {
        this.date_search[0] = ''
        this.date_search[1] = ''
      }
      this.getPlansData()
    },
    modal_propose() {
      this.selectedIndexCarrier = ''
      this.active_carrier = false
      this.title_popose = 'เลือกแผนงานที่ต้องการเสนอ (1/2)'
      this.offer_upload_file = null
      this.modal_offer.truck_quantity = ''
      this.modal_offer.cost = ''
      this.modal_offer.note = ''
      this.$bvModal.show('modal_propose_plan')
      // console.log(this.plans_data_carrier)
    },
    open_modal_detail(data) {
      this.offer_detail_data = data
      this.$refs.plandetail.bt_detail(data)
    },
    next_propose() {
      var index = this.selectedIndexCarrier
      if (index !== '') {
        var carrier = this.plans_data_carrier[index]
        // console.log(carrier)
        // console.log('sdsd')
        // console.log(this.plan_data_selcet)
        this.active_carrier = true
        this.title_popose = 'เลือกแผนงานที่ต้องการเสนอ (2/2)'
        this.obj_sel_carrier = carrier
        this.modal_offer.truck_quantity = carrier.rawData.number_of_cars
        this.modal_offer.cost = parseFloat(this.plan_data_selcet.rawData.expected_price)
        this.modal_offer.note = ''
      } else {
        // console.log('กรุณาเลือกแผนงาน')
      }
    },
    cancel_propose() {
      this.$bvModal.hide('modal_propose_plan')
    },
    sent_propose() {
      var index = this.selectedIndexCarrier

      if (index !== '') {
        var carrier = this.plans_data_carrier[index]
        var order = this.plan_data_selcet
        // console.log(carrier)
        // console.log(order)

        if (!this.checkFormValidity()) {
          return
        }

        this.askSendAlert(carrier, order)
      } else {
        // console.log('กรุณาเลือกแผนงาน')
      }
    },
    back_propose() {
      this.active_carrier = false
      this.title_popose = 'เลือกแผนงานที่ต้องการเสนอ (1/2)'
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    isNumber(val) {
      if (isNaN(Number(val.key))) {
        return val.preventDefault()
      }
    },
    handleFileUpload() {
      var curr_file = this.$refs.offerfile.files[0]
      var thisVue = this
      this.offer_upload_display = curr_file.name

      if (curr_file.type != 'image/png' && curr_file.type != 'image/jpeg' && curr_file.type != 'application/pdf') {
        this.offer_correct_file = false
      } else {
        this.offer_correct_file = true
      }
      let reader = new FileReader()
      //// console.log(rowFile)
      reader.addEventListener('load', () => {
        //reader.result
        var dataPush = {
          name: curr_file.name,
          data: reader.result,
          type: curr_file.type,
        }
        thisVue.offer_upload_file = dataPush
        // console.log('finish read')
      })
      reader.readAsDataURL(curr_file)
    },
    askSendAlert(carrier, order) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ “เสนองาน” ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendPlanEstimate(carrier, order)
        } else {
          return false
        }
      })
    },
    sendPlanEstimate(carrier, order) {
      var that = this

      var data = {
        id_order: order.rawData.id_order,
        price: this.modal_offer.cost,
        propose: this.modal_offer.note,
        id_member: carrier.rawData.id_member,
        offer_file: this.offer_upload_file,
        id_carrier: carrier.rawData.id_carrier,
        number_of_cars: parseInt(this.modal_offer.truck_quantity),
        id_member_goods_owner: order.rawData.id_member,
        vehicle_type_id: carrier.rawData.vehicle_type_id,
        vehicle_type_group_id: carrier.rawData.vehicle_type_group_id,
        account_data: this.account_data,
        plan_raw_data: order.rawData,
      }
      service.postService('saveToEstimate', data).then(async rp => {
        if (rp.message == 'save Data Success') {
          //AFTER SAVE
          that.$bvModal.hide('modal_propose_plan') //CLOSE MODAL
          global_func.alertMessage(that.lang.alert.offer_successfully, '', 'success')

          setTimeout(function() {
            that.getEstimateThisOrder(that.plan_data_selcet)
          }, 500)
        } else {
          Swal.fire({
            icon: 'error',
            title: that.lang.alert.failed,
            confirmButtonText: that.lang.btn.close,
          })
        }
      })
    },
    arkCanclePropose(carrier) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“ไม่สนใจข้อเสนอนี้”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendDeleteEstimate(carrier)
        } else {
          return false
        }
      })
    },
    sendDeleteEstimate(carrier) {
      var that = this
      var obj_es = {
        id_estimate: carrier.id_estimate,
        id_member: carrier.rawData.id_member,
        id_member_goods_owner: carrier.rawData.id_member_goods_owner,
        id_order: carrier.rawData.id_order,
        account_data: this.account_data,
      }
      var es_data = {
        estimate_data: obj_es,
      }
      service
        .postService('sendDeleteEstimate', {
          estimate_data: es_data,
        })
        .then(rp => {
          // console.log(rp)
          global_func.alertMessage('ยกเลิกข้อเสนอเรียบร้อย', '', 'success')
          setTimeout(function() {
            that.getEstimateThisOrder(that.plan_data_selcet)
          }, 1000)
        })
    },
    routersClick(link) {
      this.$router.push({ path: link })
    },
    selectPlanSendOffer(index) {
      this.selectedIndexCarrier = index
    },
    findService_other(service_other_data) {
      var id_obj = service_other_data.other_services.split('|')
      var date_return = ''
      for (let index = 0; index < this.data_service_etc.length; index++) {
        const element = this.data_service_etc[index]
        // console.log('บริการเสริม')
        // console.log(element)
        for (let index2 = 0; index2 < id_obj.length; index2++) {
          const id = id_obj[index2]
          if (id == element.service_etc_id) {
            if (date_return != '') {
              date_return += ', '
            }
            if (this.account_lang == 'en') {
              date_return += element.name_en
            } else {
              date_return += element.name_th
            }
            // if service = อื่นๆ
            if (id == '5') {
              date_return += ' (' + service_other_data.other_services_detail + ')'
            }
          }
        }
      }
      return date_return
    },
    updateStar(val, index){
      // console.log(val)
      var that = this
      let mode = 'unsub'
      if(val.star_flag == '0') {
        mode = 'sub'
      }
      if(typeof index !== 'undefined') {
        if (this.plans_row_data[index].star_flag == '0') {
          this.plans_row_data[index].star_flag = '1'
        } else {
          this.plans_row_data[index].star_flag = '0'
        }
      } else {
        for (let index2 = 0; index2 < this.plans_row_data.length; index2++) {
          const element = this.plans_row_data[index2]
          if(val.rawData.id_carrier_tracking == element.rawData.id_carrier_tracking) {
            if (this.plans_row_data[index2].star_flag == '0') {
              this.plans_row_data[index2].star_flag = '1'
              this.plan_data_selcet.star_flag = '1'
            } else {
              this.plans_row_data[index2].star_flag = '0'
              this.plan_data_selcet.star_flag = '0'
            }
          }
        }
      }

      service
        .postService('updateSubscribe', {
          account_data : this.account_data,
          provider_id : val.rawData.id_member,
          mode : mode,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getSubscribeData()
            // setTimeout(() => {
            //   that.getPlansData()
            // }, 100)
          }
        })
    },
    getSubscribeData() {
      service
        .postService('getSubscribeData', {
          account_data : this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.subscribe_data = []
            this.subscribe_data = rp.data
          }
          // console.log(this.subscribe_data)
        })
    },
    findStarFlag(id_member) {
      let subscribe_data = this.subscribe_data
      let flag = '0'
      for (let index = 0; index < subscribe_data.length; index++) {
        const element = subscribe_data[index]
        if(element.provider_id == id_member) {
          flag = '1'
        }
      }
      return flag
    },
    bindingProviderStar(data) {
      let provider_data = data
      let provider_data_1 = []
      let provider_data_2 = []
      let provider_data_return = []

      // star first
      for (let index = 0; index < provider_data.length; index++) {
        const element = provider_data[index]
        if(element.star_flag == '1') {
          provider_data_1.push(element)
        } else {
          provider_data_2.push(element)
        }
      }

      for (let index2 = 0; index2 < provider_data_1.length; index2++) {
        const element = provider_data_1[index2]
        provider_data_return.push(element)
      }

      for (let index3 = 0; index3 < provider_data_2.length; index3++) {
        const element = provider_data_2[index3]
        provider_data_return.push(element)
      }

      return provider_data_return
      
    },
    askCreateSpecificPlans() {
      var carrier = this.plan_data_selcet
      // console.log(order)
      Swal.fire({
        title: 'คุณยืนยันที่จะสร้าง \nแผนงานเฉพาะ \nใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.createSpecificPlans(carrier)
        } else {
          return false
        }
      })
    },
    createSpecificPlans(data) {
      let carrier_plan = data
      // console.log(carrier_plan)
      sessionStorage.setItem('specific_carrier_plan', JSON.stringify(carrier_plan))
      this.routersClick('/carrier/create')
    },
    getAllProfilePicture(all_provider_id) {
      let provider_id = [...new Set(all_provider_id)]
      // console.log(provider_id)
      service
        .postService('getAllProfilePicture', {
          id_member_data: provider_id,
        })
        .then(rp => {
          // console.log(rp)
          this.setProfilePicture(rp.data)
        })
    },
    setProfilePicture(profile_picture_data) {
      let temp_plans_row_data = this.plans_row_data
      this.plans_row_data = []
      for (let index = 0; index < temp_plans_row_data.length; index++) {
        let element = temp_plans_row_data[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.rawData.id_member
        })
        element.pic_profile = blob[0].pic_profile
        this.plans_row_data.push(element)
      }
      // console.log('plans_row_data', this.plans_row_data)
    },
    routersClick2(value) {
      // eslint-disable-next-line no-undef
      let link = 'plans_display'
      this.$router.push({ path: link + `/?data=${value.rawData.id_carrier_tracking}` })
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
          <div v-if="plans_display_status" class="col-main-menu h-100vh summary-of-plan" :class="{ active: plans_display_status }">
            <div class="card" style="max-height: 87.5vh; min-height: 87.5vh">
              <b-row>
                <b-col>
                  <div class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
                    <div style="padding: 1% 1% 0 1%" class="font-size-18">
                      <b-row class="col-margin-bot-1">
                        <b-col lg="3">
                          <span class="d-flex justify-content-left btn_back_blue" @click="backToPlanListDisplay()"><i class="ri-arrow-left-line" style="margin-right: 5px" />ย้อนกลับ</span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col lg="11">
                          <span class="plans_detail_title">
                            {{ lang.plans_detail_display.title }}
                          </span>
                        </b-col>
                      </b-row>
                      <simplebar class="card-body" style="max-height: 71.5vh; min-height: 71.5vh; padding: 0px 20px 0px 0px">
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <!-- <img src="@/assets/images/plans_display/star_empty.png" width="24px" height="24px" style="margin-right: 1rem; margin-left: 0.6rem" /> -->
                            <i :class="plan_data_selcet.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="plan_data_selcet.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;margin-right: 0.6rem;' : 'cursor:pointer;font-size:25px;margin-right: 0.6rem;'" @click="updateStar(plan_data_selcet)" />
                            <img v-if="plan_data_selcet.pic_profile" :src="plan_data_selcet.pic_profile" width="40px" height="40px" class="rounded-circle avatar-sm" />
                            <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" class="rounded-circle avatar-sm" />
                          </b-col>
                          <b-col lg="10">
                            <b-row>
                              <b-col>
                                <span class="font-size-16">
                                  {{ plan_data_selcet.rawData.mover_name_order }}
                                </span>
                              </b-col>
                            </b-row>
                            <b-row class="col-margin-bot-1">
                              <b-col>
                                <span class="font-size-14" style="margin-right: 0.5rem"> ผู้สนใจ/Rating : </span>
                                <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ plan_data_selcet.star_score }} ) </span>
                                <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                <span class="font-size-14"> ( {{ plan_data_selcet.lover_score }} ) </span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3"> {{ $t('company_detail.tab_data.tel') }} : </b-col>
                          <b-col lg="9">
                            {{ plan_data_selcet.rawData.mover_tel }}
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3"> {{ $t('company_detail.tab_data.email') }} : </b-col>
                          <b-col lg="9">
                            {{ (plan_data_selcet.rawData.mailaddr != 'null' && plan_data_selcet.rawData.mailaddr != NULL && plan_data_selcet.rawData.mailaddr != '')? plan_data_selcet.rawData.mailaddr : ' - ' }}
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3"> {{ $t('company_detail.title') }} : </b-col>
                          <b-col lg="9">
                            <b-link class="font-size-16" href="#" @click="bt_company_detail(plan_data_selcet.rawData)">{{ $t('plans_display.provider_display.tab1.detail') }}</b-link>
                          </b-col>
                        </b-row>

                        <hr />

                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans_detail_topic">
                              {{ lang.plans_detail_display.code }}
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.id_carrier_tracking }}
                              <!-- id_order_tracking -->
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans_detail_topic">
                              {{ lang.plans_detail_display.date_create }}
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans_detail_topic"> {{ lang.plans_detail_display.status }} : </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.status_name }}
                            </span>
                            <span class="plans-row-data-content font-color-red"> ( เสนอราคาถึงวันที่ {{ plan_data_selcet.rawData.period | moment('dd DD/MM/YYYY') }} ) </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.goods_type }}
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.goods_type_name_full }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.location }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <!-- DEPARTURE LOCATION AND DESTINATION LOCATION -->
                            <DisplayLocation ref="location_display" :arrlocation="plan_location" />
                            <!--  -->
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.total_distance }}
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="plans-row-data-content font-color-blue"> {{ plan_data_selcet.rawData.total_distance }} {{ lang.plans_detail_display.km }} </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.truck_type }}
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="plans-row-data-title">
                              <img v-if="plan_data_selcet.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                              {{ plan_data_selcet.truck_type_name }}
                            </span>
                          </b-col>
                        </b-row>

                        <!-- <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content"> จำนวนรถ : </span>
                          </b-col>
                          <b-col lg="10">
                            <span v-if="plan_data_selcet.rawData.number_of_cars != '' && plan_data_selcet.rawData.number_of_cars !== null" class="plans-row-data-content font-color-blue"> {{ plan_data_selcet.rawData.number_of_cars }} คัน </span>
                            <span v-else class="plans-row-data-content font-color-blue"> - </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content"> น้ำหนักที่บรรทุกได้ : </span>
                          </b-col>
                          <b-col lg="10">
                            <span class="plans-row-data-content font-color-blue"> {{ plan_data_selcet.rawData.payload }} ตัน </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content"> หมายเหตุ : </span>
                          </b-col>
                          <b-col lg="10">
                            <span v-if="plan_data_selcet.rawData.note != '' && plan_data_selcet.rawData.note !== null" class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.rawData.note }}
                            </span>
                            <span v-else class="plans-row-data-content"> - </span>
                          </b-col>
                        </b-row> -->
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content">
                              บริการอื่นๆ
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span v-if="plan_data_selcet.rawData.other_services != ''" class="plans-row-data-content font-color-blue">
                              {{ findService_other(plan_data_selcet.rawData) }}
                            </span>
                            <span v-else class="plans-row-data-content font-color-blue">
                              -
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2">
                            <span class="plans-row-data-content">
                              ราคา
                            </span>
                          </b-col>
                          <b-col lg="10">
                            <span v-if="plan_data_selcet.conv_price != ''" class="plans-row-data-title"> {{ plan_data_selcet.conv_price }} {{ lang.bath }} </span>
                            <span v-else class="plans-row-data-title">
                              -
                            </span>
                          </b-col>
                        </b-row>

                        <hr />
                        <div v-if="estimate_status">
                          <b-row class="col-margin-bot-1">
                            <b-col lg="6">
                              <div class="d-flex justify-content-center big_btn_blue font-size-16" style="width: 100%" @click="modal_propose">
                                <span class="margin-top-bot-auto">เสนอแผนงานขนส่ง</span>
                              </div>
                            </b-col>
                            <b-col lg="6">
                              <div class="d-flex justify-content-center big_btn_white font-size-16" style="width: 100%" @click="askCreateSpecificPlans()">
                                <span class="margin-top-bot-auto">สร้างแผนงานเฉพาะ</span>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                        <div v-else>
                          <b-row class="col-margin-bot-1">
                            <b-col>
                              <span class="plans_detail_title"> แผนงานรถว่างที่มีการเสนอ </span>
                            </b-col>
                          </b-row>
                          <b-row style="padding-right: 1rem">
                            <div v-for="(option, index) in estimate_this_order" :key="index" class="carrier-row-data-offer plans-col" style="margin-left: 1rem; padding: 1rem">
                              <b-row class="col-margin-bot-1">
                                <b-col>
                                  <div class="carrier-detail">
                                    <b-row class="col-margin-bot-1 font-size-16">
                                      <b-col lg="12">
                                        <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width: 70px; height: 34px" />
                                        <span style="padding-left: 5px; color: #343c48; font-size: 24px">&nbsp;&nbsp;{{ option.truck_type_name }}</span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col>
                                        <span class="plans-row-data-content"> {{ $t('plans_carrier_display.number_of_car') }} : </span>
                                        <span class="plans-row-data-content font-color-blue"> {{ option.rawData.number_of_cars }} {{ $t('plans_carrier_display.car') }} </span>
                                      </b-col>
                                      <b-col>
                                        <span class="plans-row-data-content"> {{ $t('plans_carrier_display.payload') }} : </span>
                                        <span class="plans-row-data-content font-color-blue"> {{ option.rawData.payload }} {{ lang.tons }} </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col>
                                        {{ $t('plans_carrier_display.pick_up_1') }} :
                                        <span style="color: #0098d1"> {{ option.rawData.depart_address1 }} - {{ option.rawData.depart_date | moment('dd DD/MM/YYYY HH:mm') }} </span>
                                      </b-col>
                                      <b-col>
                                        {{ $t('plans_carrier_display.drop_1') }} :
                                        <span style="color: #0098d1"> {{ option.rawData.arrive_address1 }} - {{ option.rawData.arrive_date | moment('dd DD/MM/YYYY HH:mm') }} </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="custom-margin">
                                      <b-col lg="3">
                                        <button class="btn btn-sm" style="color: #343c48; background: #ffffff; border: 1px solid #b0bac4" @click="open_modal_detail(option.rawData)">ดูแบบละเอียด</button>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="col-margin-bot-1 font-size-14">
                                <b-col lg="6">
                                  <b-button variant="primary" style="width: 100%" @click="routersClick2(option)">ไปหน้า "แผนงานรถว่าง"</b-button>
                                </b-col>
                                <b-col lg="6">
                                  <b-button variant="danger" style="width: 100%" @click="arkCanclePropose(option)">ยกเลิกการเสนองาน</b-button>
                                </b-col>
                              </b-row>
                              <b-row class="col-margin-bot-1 font-size-14">
                                <b-col>
                                  <span class="d-sm-inline-block">Tip : คุณสามารถตรวจสอบสถานะแผนงานรถว่างของคุณได้จากเมนู "<img src="@/assets/images/right_bar_menu/delivery-truck (1).svg" height="18px" width="18px" style="margin-left: 0.5rem; margin-right: 0.5rem" />แผนงานรถว่าง"</span>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                        </div>
                      </simplebar>
                    </div>
                  </div>
                </b-col>

                <b-col>
                  <div class="card-body" style="max-height: 87.5vh; min-height: 87.5vh; padding 1.25rem; margin-right: 0.6rem;">
                    <div style="padding: 1% 1% 0 1%">
                      <RighrMap :obj="plan_data_selcet.rawData" />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </transition>

        <!-- MAIN OFFER PAGE-->
        <div style="padding: 2rem 2rem 0rem 2rem">
          <b-row>
            <b-col lg="2" class="col-margin-bot-1">
              <div class="form-group" @click="click_select_depart()">
                <b-form-input v-model="selectedDeparture" class="form-control grey_hover" type="text" placeholder="จังหวัด(ต้นทาง)" disabled>
                  <!-- <i class="ri-search-line" /> -->
                </b-form-input>
              </div>
              <b-modal id="modal_prov_depart" centered size="xl" :title="lang.title" title-class="font-size-24" hide-footer>
                <ProvinceComp :obj="obj_sel_depart" @select_prov="select_prov_depart" />
              </b-modal>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <div class="form-group" @click="click_select_arrive()">
                <b-form-input v-model="selectedArrival" class="form-control grey_hover" type="text" placeholder="จังหวัด(ปลายทาง)" disabled />
              </div>
              <b-modal id="modal_prov_arrive" centered size="xl" :title="lang.title" title-class="font-size-24" hide-footer>
                <ProvinceComp :obj="obj_sel_arrive" @select_prov="select_prov_arrive" />
              </b-modal>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <div class="form-group" @click="activeSelectTruckType()">
                <b-form-input v-model="selectedTruckType" class="form-control grey_hover" type="text" placeholder="เลือกประเภทรถ" disabled />
              </div>
              <b-modal id="modal_select_truckType" centered size="lg" :title="lang.plans_detail_display.truck_type" title-class="font-size-24" hide-footer>
                <TruckTypeComp ref="select_truckType_comp" :obj="[dataTruckType, dataTruckTypeGroup, 'carrier']" @sendTruckTypeData="getTruckType_select" />
              </b-modal>
            </b-col>
            <b-col lg="2">
              <date-picker v-model="date_search" input-class="form-control" format="DD-MM-YYYY" type="date" range placeholder="วันเริ่มเดินทาง" />
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <div class="d-flex justify-content-center big_btn_blue" @click="search_goods()">
                <span class="margin-top-bot-auto"><i class="ri-search-line" /> ค้นหา</span>
              </div>
            </b-col>
            <b-col />
          </b-row>

          <b-row v-if="plans_data_status">
            <simplebar class="card-body plans-main-display" style="padding: 0px">
              <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight" tag="div">
                <b-col v-for="(option, index) in plans_row_data" :key="index+1" lg="12" class="plans-col">
                  <div class="plans-row-data">
                    <div style="cursor: pointer;" @click="activePlansDisplay(option)">
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-title">
                            <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                            {{ option.truck_type_name }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.goods_type }} : </span>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.goods_type_name }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.weight }} : </span>
                          <span class="plans-row-data-content font-color-blue"> {{ option.rawData.quantity }} {{ lang.tons }} </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.create_date }} : </span>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.prices }} : </span>
                          <span class="plans-row-data-title"> {{ option.conv_price }} {{ lang.bath }} </span>
                        </b-col>
                      </b-row>
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-content">
                            {{ lang.pick_up_1 }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content">
                            <i class="ri-arrow-right-fill" />
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content">
                            {{ lang.drop_1 }}
                          </span>
                        </b-col>
                        <b-col />
                      </b-row>
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.departure_address }}
                            <br />
                            {{ option.pickup_time }}
                          </span>
                        </b-col>
                        <b-col>
                          <b-link class="font-size-16" href="#" style="text-decoration: underline">1 จุดแวะ</b-link>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.arrival_address }}
                            <br />
                          </span>
                        </b-col>
                        <b-col />
                      </b-row>
                    </div>
                    <hr />
                    <b-row>
                      <b-col class="d-flex align-items-center">
                        <!-- <img src="@/assets/images/plans_display/star_empty.png" width="24px" height="24px" style="margin-right: 0.6rem" /> -->
                        <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;margin-right: 0.6rem;' : 'cursor:pointer;font-size:25px;margin-right: 0.6rem;'" @click="updateStar(option, index)" />
                        <img v-if="option.pic_profile" :src="option.pic_profile" width="40px" height="40px" style="margin-right: 0.6rem" class="rounded-circle avatar-xs" />
                        <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" style="margin-right: 0.6rem" class="rounded-circle avatar-xs" />
                        {{ option.rawData.mover_name_order }}
                        <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" style="margin-left: 1rem" />
                        <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                        <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                        <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                      </b-col>
                      <b-col class="d-flex align-items-center">
                        <span v-if="option.count_offer > 0" class="status-plan"> เสนองานแล้ว </span>
                        <span v-else> - </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </transition-group>
            </simplebar>
          </b-row>

          <b-row v-else>
            <b-col>
              <br />
              <br />
              <br />
              <br />
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <img src="@/assets/images/search.png" height="188px" />
              </b-col>
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <span style="font-size: 24px; font-weight: 600;">
                  ไม่พบข้อมูลงานขนส่ง
                </span>
              </b-col>
            </b-col>
          </b-row>
        </div>
        <!-- END MAIN OFFER PAGE -->
      </b-col>
    </b-row>

    <b-modal id="modal_propose_plan" centered size="lg" :title="title_popose" title-class="font-18">
      <simplebar style="max-height: 67vh; min-height: 67vh; padding-top: 0px">
        <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
          <div v-if="active_carrier" class="h-100vh summary-of-plan-modal" :class="{ active: active_carrier }">
            <b-row>
              <b-col>
                <div class="carrier-detail">
                  <b-row class="col-margin-bot-1">
                    <b-col lg="12">
                      <img v-if="obj_sel_carrier.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width: 70px; height: 34px" />
                      <img v-else-if="obj_sel_carrier.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width: 70px; height: 34px" />
                      <img v-else-if="obj_sel_carrier.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width: 70px; height: 34px" />
                      <img v-else-if="obj_sel_carrier.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width: 70px; height: 34px" />
                      <img v-else-if="obj_sel_carrier.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width: 70px; height: 34px" />
                      <img v-else-if="obj_sel_carrier.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width: 70px; height: 34px" />
                      <span style="padding-left: 5px; color: #343c48; font-size: 24px">&nbsp;&nbsp;{{ obj_sel_carrier.truck_type_name }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="col-margin-bot-1">
                    <b-col>
                      <span class="plans-row-data-content"> {{ $t('plans_carrier_display.number_of_car') }} : </span>
                      <span class="plans-row-data-content font-color-blue"> {{ obj_sel_carrier.rawData.number_of_cars }} {{ $t('plans_carrier_display.car') }} </span>
                    </b-col>
                    <b-col>
                      <span class="plans-row-data-content"> {{ $t('plans_carrier_display.payload') }} : </span>
                      <span class="plans-row-data-content font-color-blue"> {{ obj_sel_carrier.rawData.payload }} {{ lang.tons }} </span>
                    </b-col>
                  </b-row>
                  <b-row class="col-margin-bot-1">
                    <b-col>
                      {{ $t('plans_carrier_display.pick_up_1') }} :
                      <span style="color: #0098d1"> {{ obj_sel_carrier.rawData.depart_address1 }} </span>
                    </b-col>
                    <b-col>
                      {{ $t('plans_carrier_display.drop_1') }} :
                      <span style="color: #0098d1"> {{ obj_sel_carrier.rawData.arrive_address1 }} </span>
                    </b-col>
                  </b-row>
                  <b-row class="custom-margin">
                    <b-col lg="3">
                      <button class="btn btn-sm" style="color: #343c48; background: #ffffff; border: 1px solid #b0bac4" @click="open_modal_detail(obj_sel_carrier.rawData)">ดูแบบละเอียด</button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <div style="padding: 1rem">
              <b-row style="margin-bottom: 1rem">
                <b-col lg="2">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.truck_type }}
                  </span>
                </b-col>
                <b-col lg="10">
                  <img v-if="obj_sel_carrier.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width: 70px; height: 34px" />
                  <img v-else-if="obj_sel_carrier.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width: 70px; height: 34px" />
                  <img v-else-if="obj_sel_carrier.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width: 70px; height: 34px" />
                  <img v-else-if="obj_sel_carrier.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width: 70px; height: 34px" />
                  <img v-else-if="obj_sel_carrier.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width: 70px; height: 34px" />
                  <img v-else-if="obj_sel_carrier.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width: 70px; height: 34px" />
                  <span style="padding-left: 5px; color: #343c48; font-size: 24px">&nbsp;&nbsp;{{ obj_sel_carrier.truck_type_name }}</span>
                </b-col>
              </b-row>
              <form ref="form">
                <b-form-group id="" label-cols-sm="1" label-cols-lg="2" label="จำนวนรถ:" label-for="text">
                  <div class="input-group prepend">
                    <b-form-input v-model="modal_offer.truck_quantity" type="number" class="add_car form-control" placeholder="ระบุจำนวนรถ" />
                    <div class="input-group-prepend">
                      <span class="input-group-text custom-input-group-prepend-text" style="cursor: default;">คัน</span>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group id="" label-cols-sm="1" label-cols-lg="2" label="จำนวนรถ:" label-for="text">
                  <div class="input-group prepend">
                    <VuetifyMoney v-model="modal_offer.cost" placeholder="ระบุราคา" :readonly="readonly5" :disabled="disabled5" :outlined="outlined5" :clearable="clearable5" :options="options5" />
                    <div class="input-group-prepend">
                      <span class="input-group-text custom-input-group-prepend-text" style="cursor: default;">บาท</span>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group id="" label-cols-sm="1" label-cols-lg="2" label="ใบเสนอราคา:" label-for="text">
                  <div class="custom-file">
                    <input id="offerfile" ref="offerfile" type="file" class="custom-file-input" :class="{ 'is-invalid': !offer_correct_file }" @input="handleFileUpload()" />
                    <label v-if="offer_upload_display !== null" class="custom-file-label" for="customFile"> {{ offer_upload_display }} </label>
                    <label v-else class="custom-file-label" for="customFile"> เลือกไฟล์</label>
                  </div>
                  <span style="color: red; font-size: 10px">(รองรับไฟล์ .pdf, .jpg, .png)</span>
                </b-form-group>
                <b-form-group id="" label-cols-sm="1" label-cols-lg="2" label="หมายเหตุ:" label-for="text">
                  <textarea id="billing-address" v-model="modal_offer.note" class="form-control" rows="3" placeholder="ระบุหมายเหตุ" />
                </b-form-group>
              </form>
            </div>
          </div>
        </transition>

        <div v-if="!active_carrier">
          <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight" tag="div">
            <div v-for="(option, index) in plans_data_carrier" :key="index+1" class="carrier-row-data plans-col">
              <b-row v-if="option.rawData.carrier_status == '0' || option.rawData.carrier_status == '1'">
                <b-col lg="1" class="d-flex flex-row-reverse align-items-center">
                  <b-form-radio v-model="selectedIndexCarrier" :value="index" name="radio-propose" />
                </b-col>
                <b-col lg="11">
                  <div class="carrier-detail" @click="selectPlanSendOffer(index)">
                    <b-row class="col-margin-bot-1">
                      <b-col lg="12">
                        <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width: 70px; height: 34px" />
                        <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width: 70px; height: 34px" />
                        <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width: 70px; height: 34px" />
                        <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" style="width: 70px; height: 34px" />
                        <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" style="width: 70px; height: 34px" />
                        <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width: 70px; height: 34px" />
                        <span style="padding-left: 5px; color: #343c48; font-size: 24px">&nbsp;&nbsp;{{ option.truck_type_name }}</span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        <span class="plans-row-data-content"> {{ $t('plans_carrier_display.number_of_car') }} : </span>
                        <span class="plans-row-data-content font-color-blue"> {{ option.rawData.number_of_cars }} {{ $t('plans_carrier_display.car') }} </span>
                      </b-col>
                      <b-col>
                        <span class="plans-row-data-content"> {{ $t('plans_carrier_display.payload') }} : </span>
                        <span class="plans-row-data-content font-color-blue"> {{ option.rawData.payload }} {{ lang.tons }} </span>
                      </b-col>
                    </b-row>
                    <b-row class="col-margin-bot-1">
                      <b-col>
                        {{ $t('plans_carrier_display.pick_up_1') }} :
                        <span style="color: #0098d1"> {{ option.rawData.depart_address1 }} - {{ option.rawData.depart_date | moment('dd DD/MM/YYYY HH:mm') }} </span>
                      </b-col>
                      <b-col>
                        {{ $t('plans_carrier_display.drop_1') }} :
                        <span style="color: #0098d1"> {{ option.rawData.arrive_address1 }} - {{ option.rawData.arrive_date | moment('dd DD/MM/YYYY HH:mm') }} </span>
                      </b-col>
                    </b-row>
                    <b-row class="custom-margin">
                      <b-col lg="3">
                        <button class="btn btn-sm" style="color: #343c48; background: #ffffff; border: 1px solid #b0bac4" @click="open_modal_detail(option.rawData)">ดูแบบละเอียด</button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </transition-group>
        </div>
      </simplebar>

      <template #modal-footer class="d-flex align-items-center">
        <b-button v-show="!active_carrier" size="md" variant="primary" @click="next_propose()"> ถัดไป </b-button>
        <b-button v-show="!active_carrier" size="md" variant="outline-secondary" @click="cancel_propose()"> ยกเลิก </b-button>
        <b-button v-show="active_carrier" size="md" variant="success" @click="sent_propose()"> ตกลง </b-button>
        <b-button v-show="active_carrier" size="md" variant="outline-secondary" @click="back_propose()"> ย้อนกลับ </b-button>
      </template>
    </b-modal>

    <PlanDetail ref="plandetail" :obj="{}" />
    <!-- <ModalCompany ref="modalCompany" :obj="{}" /> -->
    <ModalCompany ref="refCompany" :obj="{}" />
    <!-- <TruckTypeComp ref="refSelectTruckType" :obj="[]" @sendTruckTypeData="getTruckType_select" /> -->
  </Layout>
</template>
<style scoped>
body {
  overflow: hidden;
}
hr {
  border: 0.5px solid #e4e4e4;
}
.col-main-menu-tran {
  padding: 0px;
}
.h-100vh {
  height: 100% !important;
}
.plans-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  max-height: 320px;
  min-height: 250px;
  padding: 1rem;
}
.plans-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.plans-col {
  /* transition: all 1s; */
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.plans-main-display {
  max-height: 77.5vh;
  min-height: 77.5vh;
}
.plans-row-data-title {
  font-size: 24px;
  font-weight: 500;
}
.plans-row-data-content {
  font-size: 16px;
  font-weight: 500;
}
.custom-margin {
  margin-bottom: 0.5rem;
}
.summary-of-plan.active {
  position: absolute;
  width: 100%;
  z-index: 13;
}
.summary-of-plan-modal.active {
  width: 100%;
  z-index: 13;
}
.plans_detail_title {
  font-size: 32px;
  font-weight: 500;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
.col-margin-bot-1 {
  margin-bottom: 0.6rem;
}
.service-provider-display {
  padding: 20px;
}
.status-plan {
  background-color: #0098d1;
  color: #ffffff;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
  border-radius: 1rem;
}

.carrier-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 5px;
  opacity: 1;
}
.carrier-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.carrier-detail {
  background: #f2f9fc 0% 0% no-repeat padding-box;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
.carrier-row-data-offer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
}
.add_car {
  border-right: 0px;
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}
</style>
